<template>
  <main>
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="showModal"
      @update:show="closeModal($event)"
      :closeOnBackdrop="false"
      size="lg"
    >
      <loading-overlay
        :active="loadingOverlay"
        :is-full-page="true"
        loader="bars"
      />

      <CRow>
        <CCol sm="12">
          <label><b class="text-danger">*</b> {{$t('label.service')}}</label>
          <v-select
            :placeholder="$t('label.select')+$t('label.service')"
            :options="computedServiceList"
            class="select-services--port"
            @input="selectServices"
            v-model.trim="$v.ServiceModel.$model"
            :class="
              computedInputSelect
                ? 'select-services--correct'
                : 'select-services--error'
            "
          />
        </CCol>
        <CCol sm="12">
          <label class="mt-3"><b class="text-danger">*</b> {{$t('label.frecuency')}}</label>
          <CSelect
            :options="computedfrecuencyList"
            @change="selectFrecuency($event)"
            :invalid-feedback="$t('label.required')"
            v-model.trim="$v.FrecuencyId.$model"
            :is-valid="hasError($v.FrecuencyId)"
            :value="FrecuencyId"
          />
        </CCol>

        <CCol sm="12" v-if="false">
          <label class="mt-3"><b class="text-danger">*</b> STATUS</label>
          <CSelect
            :options="optionsStatus"
            @change="selectStatus($event)"
            v-model.trim="$v.Status.$model"
            :is-valid="statusSelectColor"
            :value.sync="Status"
          />
        </CCol>
        <CCol sm="12" class="justify-content-end">
          <CButton square color="dark" class="mt-1 float-right" @click="nuevoPuerto(false)" >
            <CIcon :name="icono" /><span class="ml-1"
              >{{$t('label.nuevo')+$t('label.port')}}</span
            >
          </CButton>
        </CCol>
        <CCol sm="12" v-if="newPort">
          <AddPort :value="newPort" @child-refresh="nuevoPuerto" />
        </CCol> 
        <CCol sm="12">
          <dataTableExtended
     class="align-center-row-datatable"
            size="lg"
            :fields="fields"
            :items="computedPortList"
            :items-per-page="5"
            column-filter
            pagination
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            :loading="loadingTable"
          >
            <template #checked="{ item }">
              <td>
                <CInputCheckbox
                  custom
                  class="checkbook-filter-port--services"
                  :checked="item.initialActive"
                  @update:checked="PorCheckedOrUncheked($event, item)"
                />
              </td>
            </template>
            <!--<template #checked-filter>
              <td>
                <CInputCheckbox
                  :label="$t('label.select')+$t('label.all')"
                  class="checkbook-filter-port--services"
                  custom
                  :checked="selectAll"
                  @update:checked="selectAllChecked($event)"
                />
              </td>
            </template>
            <template #options="{ item }">
              <td>
                <CButtonGroup>
                  <CButton
                    square
                    color="primary ml-2"
                    class="d-flex align-items-center"
                    v-c-tooltip="{
                      content: $t('label.edit')+$t('label.bulkCargo'),
                      placement: 'top',
                    }"
                  >
                    <CIcon name="cil-note" />
                  </CButton>
                </CButtonGroup>
              </td>
            </template>-->
          </dataTableExtended>
        </CCol>
      </CRow>

      <template #footer>
        <!--<CButton
          v-if="!editModal"
          outline
          color="success"
          @click="saveServicePortId"
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton
          v-if="editModal"
          outline
          color="success"
          @click="submitUpdateServciePort"
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>-->
        <CButton color="dark" @click="closeModal(false)">
          <CIcon name="cil-chevron-circle-left-alt" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </main>
</template>
<script>
import UpperCase from '@/_validations/uppercase-directive';
import GeneralMixin from '@/_mixins/general';
import puerto from '@/_validations/servicio/puerto';
import CargaGranel from '@/_mixins/carga-granel';
import AddPort from '@/pages/puerto/add-puerto';
import { mapState } from 'vuex';

const tableTextHelpers = {
  tableFilterText: {
    label: 'FILTRAR:',
    placeholder: 'PUERTO',
  },
  itemsPerPageText: {
    label: 'PUERTOS POR PAGINA:',
  },
  noItemsViewText: {
    noResults: 'NO SE ENCONTRARON RESULTADOS',
    noItems: 'NO HAY REGISTROS DISPONIBLES',
  },
};

function data() {
  return {
    showModal: false,
    selectAll: false,
    loadingOverlay: false,
    loadingTable: false,
    largo:  'lg',
    icono: 'cil-chevron-circle-right-alt',
    newPort: false,
    optionsStatus: [
      { label: this.$t('label.ACTIVO'), value: 1 },
      { label: this.$t('label.INACTIVO'), value: 0 },
    ],
    Status: 1,
    fields: [
      { label: '#', key: 'Nro', sorter: false, filter: false },
      { label: 'PUERTO', key: 'PortName', _classes: 'text-uppercase' },
      {
        label: 'APLICA',
        key: 'checked',
        _classes: 'text-uppercase',
        sorter: false,
        filter: false,
      },
      //{ key: 'options', label: '', sorter: false, filter: false },
    ],
    tableText: tableTextHelpers,
    itemsPuerto: [],
    ServiceId: '',
    ServiceModel: '',
    serviceList: [],
    FrecuencyId: '',
    itemsFrecuency: [],
    itemsServicesPortById: [],
    resetSelectAll: false,
  };
}

//### Funcion mounted para cargar los datos de la tabla ###
async function mountedPortList() {
  this.loadingTable = true;
  this.itemsPuerto = [];

  this.$http
    .get('Port-list?CountryId&Filter=ACTIVO')
    .then((response) => (this.itemsPuerto = response.data.data))
    .finally(() => {
      this.loadingTable = false;
    })
    .catch((e) => {
      this.loadingTable = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function computedPortList() {
  if (this.itemsPuerto.length > 0) {
    return this.itemsPuerto.map((item) => {
      if (!this.selectAll && !this.resetSelectAll) {
        const service = this.itemsServicesPortById.filter(
          (element) => element.PortId == item.PortId && element.FrecuencyId == this.FrecuencyId
        );
        return {
          ...item,
          initialActive: service.length > 0 ? true : false,
        };
      }
      if (this.selectAll && !this.resetSelectAll) {
        return {
          ...item,
          initialActive: true,
        };
      }
      if (!this.selectAll && this.resetSelectAll) {
        return {
          ...item,
          initialActive: false,
        };
      }
    });
  }
}

//### Funcion mounted para cargar los datos de la tabla ###
async function servicePorListByServiceId(id) {
  this.itemsServicesPortById = [];
  this.loadingTable = true;

  this.$http
    .get(`ServicePort-list?ServiceId=${id}`)
    .then((response) => {
      this.itemsServicesPortById = response.data.data.filter(ob => ob.FrecuencyId==this.FrecuencyId && ob.Status == "ACTIVO").map((item) => {
          return {
            ServiceId: item.ServiceId,
            PortId: item.PortId,
            FrecuencyId: item.FrecuencyId,
            Status: item.Status === 'ACTIVO' ? 1 : 0,
          };
      });
    })
    .finally(() => {
      this.loadingOverlay = false;
      this.loadingTable = false;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.loadingTable = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

//### Funcion mounted para cargar los datos de la tabla ###
async function mountedServiceList() {
  //this.loadingOverlay = true;
  await this.$http
    .get('Service-list?CompanyBranchId='+this.branch.CompanyBranchId+'&Filter=ACTIVO')
    .then((response) => {
      this.serviceList = response.data.data;
    })
    .finally(() => {
      this.loadingTable = false;
    })
    .catch((e) => {
      this.loadingTable = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

//########
// Data para las opciones del select de servicios
//########
function computedServiceList() {
  if (this.serviceList.length > 0) {
    return this.serviceList.map((item) => {
      return {
        label: item.ServiceCode,
        value: item.ServiceId,
      };
    });
  }
}

//### Funcion mounted para cargar los datos de la tabla ###
async function mountedFrecuencyList() {
  //this.loadingOverlay = true;

  await this.$http
    .get('TimeFrecuency-list?Filter=ACTIVO')
    .then((response) => {
      this.itemsFrecuency = response.data.data;
      if (!this.editModal) {
        this.FrecuencyId = response.data.data[0].TimeFrecuencyId;
      }
    })
    .finally(() => {
      this.loadingTable = false;
    })
    .catch((e) => {
      this.loadingTable = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

//########
// Data para las opciones del select de frecuencia
//########
function computedfrecuencyList() {
  if (this.itemsFrecuency.length > 0) {
    return this.itemsFrecuency.map((item) => {
      return {
        label: item.TimeFrecuencyName,
        value: item.TimeFrecuencyId,
      };
    });
  }
}

function closeModal(event) {
  this.showModal = false;
  this.openDate = false;
  this.resetData();
  this.$emit('updated-modal', event);
}

function functionDate(event) {
  if (event) {
    this.openDate = event;
  }
}

function computedInputSelect() {
  if (this.ServiceId === '') {
    return false;
  } else {
    return true;
  }
}

async function selectServices(payload) {
  if (payload !== null) {
    this.ServiceId = payload.value;
    this.ServiceModel = payload.label;
    await this.servicePorListByServiceId(this.ServiceId);
    //await this.serviceSelected(this.ServiceId);
  } else {
    this.ServiceId = '';
    this.ServiceModel = '';
  }
}

function selectFrecuency(event) {
  this.FrecuencyId = event.target.value;
  if (this.ServiceId != "") this.servicePorListByServiceId(this.ServiceId);
  this.itemsServicesPortById = this.itemsServicesPortById.map((item) => {
    if (this.Status) {
      return {
        ServiceId: this.ServiceId,
        PortId: item.PortId,
        FrecuencyId: this.FrecuencyId,
        Status: this.Status,
      };
    } else {
      return {
        ServiceId: this.ServiceId,
        PortId: item.PortId,
        FrecuencyId: this.FrecuencyId,
      };
    }
  });
}

async function PorCheckedOrUncheked(event, item) {
  this.selectAll = false;
  this.resetSelectAll = false;
  let listado = [];
  
  
  if (event === true) {
    //this.itemsServicesPortById.push(selectPort);
    listado = {
      ServiceId: this.ServiceId,
      PortId: item.PortId,
      FrecuencyId: this.FrecuencyId,
      Status: 1
    };
  } else {
    /*const i = await this.arrayObjectIndexOf(
      this.itemsServicesPortById,
      item.PortId,
      'PortId'
    );
    if (i !== -1) {
      this.itemsServicesPortById.splice(i, 1);
    }*/
    listado = {
      ServiceId: this.ServiceId,
      PortId: item.PortId,
      FrecuencyId: this.FrecuencyId,
      Status: 0
    };
  }

  this.saveServicePortId(listado);
}

function selectAllChecked(event) {
  this.itemsServicesPortById = [];
  if (event === true) {
    this.selectAll = true;
    this.resetSelectAll = false;
  } else {
    this.selectAll = false;
    this.resetSelectAll = true;
  }
}

function resetData() {
  this.ServiceId = '';
  //this.FrecuencyId = '';
  this.ServiceModel = '';
  this.Status = 1;
  this.itemsServicesPortById = [];
}

async function saveServicePortId(listado) {
  try {
    this.loadingOverlay = true;
    this.$v.$touch();

    if (this.$v.$error) {
      this.loadingOverlay = false;
      throw 'Debe seleccionarse el servicio ';
    }

    const ServicePortJson = listado;
    
    await this.$http
      .post('ServicePort-insert', ServicePortJson, {
        root: 'ServicePortJson',
      })
      .then((response) => {
        if (response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          this.$emit('refresh-data-table');
          this.loadingOverlay = false;
          //this.closeModal(false);
          //this.resetData();
          this.$notify({
            group: 'container',
            title: '¡Solicitud Exitosa!',
            text: messageSuccess,
            type: 'success',
          });
        }
      })
      .catch((e) => {
        this.loadingOverlay = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: 'error',
        });
      });
  } catch (e) {
    this.loadingOverlay = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: 'error',
    });
  }
}

function selectStatus(event) {
  this.Status = event.target.value;
  
  this.itemsServicesPortById = this.itemsServicesPortById.map((item) => {
    if (item.Status) {
      return {
        ServiceId: this.ServiceId,
        PortId: item.PortId,
        FrecuencyId: this.FrecuencyId,
        Status: this.Status,
      };
    } else {
      return {
        ServiceId: this.ServiceId,
        PortId: item.PortId,
        FrecuencyId: this.FrecuencyId,
      };
    }
  });
}

//########
// funcion para llamar al update del servcio
//########
async function submitUpdateServciePort() {
  try {
    this.$v.$touch();

    if (this.$v.$error) {
      this.loadingOverlay = false;
      throw 'Existen errores, por favor, verifique.';
    }

    this.checkSTatusServicePort();
  } catch (e) {
    this.loadingOverlay = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: 'error',
    });
  }
}

//########
// Verifica el status a la hora de realizar el update
//########
async function checkSTatusServicePort() {
  try {
    if (this.Status == 0) {
      this.$swal
        .fire({
          title:
            '¿Estás seguro desea desactivar a este Servicio asociado a puertos?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('button.confirm'),
          cancelButtonText: this.$t('button.cancel')
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.updateServicePort();
          }
        });
    } else {
      this.updateServicePort();
    }
  } catch (e) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: 'error',
    });
  }
}

//########
// llama al metodo put
//########
async function updateServicePort() {
  ///this.loadingOverlay = true;
  const ServicePortJson = this.itemsServicesPortById;
  //console.log(this.itemsServicesPortById);return false;
  await this.$http
    .post('ServicePort-insert', ServicePortJson, {
      root: 'ServicePortJson',
    })
    .then((response) => {
      if (response.status === (200 || 201)) {
        const messageSuccess = response.data.data[0].Response;
        this.$emit('refresh-data-table');
        this.loadingOverlay = false;
        this.resetData();
        this.closeModal(false);
        this.$notify({
          group: 'container',
          title: '¡Solicitud Exitosa!',
          text: messageSuccess,
          type: 'success',
        });
      }
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function nuevoPuerto(item) {
  this.Port = item;
  if (this.newPort) {
    this.newPort = false;
    this.icono = 'cil-chevron-circle-right-alt';
    this.mountedPortList();
  } else {
    this.newPort = true;
    this.icono = 'cil-chevron-circle-left-alt';
  }  
}

export default {
  name: 'modalPuerto',
  data,
  props: {
    title: String,
    modal: null,
    editModal: Boolean,
    serviceSelected: Object,
  },
  mixins: [GeneralMixin, CargaGranel],
  directives: UpperCase,
  components: {
    AddPort
  },
  watch: {
    modal: async function(val) {
      this.showModal = val;
      if (val === true) {
        this.resetData();
        await this.mountedServiceList();
        await this.mountedFrecuencyList();
        await this.mountedPortList();
        
        if (this.ServicioId!="") this.selectServices(this.ServicioId); 
      }
    },
    serviceSelected: function(val) {
      if (Object.keys(val).length !== 0) {
        this.servicePorListByServiceId(val.ServiceId);
        this.FrecuencyId = val.FrecuencyId;
        this.Status = val.Status === 'ACTIVO' ? 1 : 0;
        this.ServiceId = val.ServiceId;
        this.ServiceModel = val.ServiceCode;
      }
    },
  },
  validations: puerto,
  methods: {
    closeModal,
    functionDate,
    mountedServiceList,
    selectServices,
    mountedFrecuencyList,
    mountedPortList,
    servicePorListByServiceId,
    selectFrecuency,
    PorCheckedOrUncheked,
    selectAllChecked,
    saveServicePortId,
    resetData,
    selectStatus,
    submitUpdateServciePort,
    checkSTatusServicePort,
    updateServicePort,
    nuevoPuerto
  },
  computed: {
    computedServiceList,
    computedInputSelect,
    computedfrecuencyList,
    computedPortList,
    ...mapState({
        ServicioId: state => state.servicio.servicioId,
        user: state => state.auth.user,
        branch: (state) => state.auth.branch,
    })
  },
};
</script>
<style lang="scss">
.select-services--port .vs__dropdown-menu {
  max-height: 280px !important;
}
.select-services--correct {
  .vs__dropdown-toggle {
    border-color: #2eb85c !important;
  }
}
.select-services--error {
  .vs__dropdown-toggle {
    border-color: #e55353 !important;
  }
}
.checkbook-filter-port--services {
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #2eb85c;
    background-color: #2eb85c;
  }
}

.modal-body {
  max-height: 700px;
  overflow-y: auto;
}
</style>
